import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { mapMembresBlockToCards } from "../../../utils/mappers/membres";
import Cards from "../../Blocks/Cards";
import { ContentContainer } from "../../styles";

export default ({}) => {
  const data = useStaticQuery(graphql`
    query MembresListQuery {
      allWpMembre (
        filter: {language: {code: {eq: FR}}, status: {eq: "publish"}}
        ){
        edges {
          node {
            ...membreBlockContent
          }
        }
      }
    }
  `);
  const cards = mapMembresBlockToCards(data.allWpMembre.edges.map(edge => edge.node));
  
  return (
    <ContentContainer>
      <Cards cards={cards} />
    </ContentContainer>
  );
}