import React from "react"
import * as qs from "query-string"
import Button from "../../Button";
import { Form, Alert, FormGroup, Label, Input, FormFeedback, TextArea, Submit, ContactContainer, ContactAddress } from "./styles";

export const Contact = ({location, success, error, submit, formFields, name}) => {
  const isBrowser = typeof window !== `undefined`;
  // formFields is a string when used in mdx shortcodes
  const fieldsDefinition = [...formFields];
  const fieldsDefault = {};
  const pathname = location ? location.pathname : isBrowser && window.location.pathname;
  const locationValues = location ? qs.parse(location.search) : isBrowser && qs.parse(window.location.search);
  fieldsDefinition.map((field) => {
    fieldsDefault[field.name] = locationValues[field.name] ? locationValues[field.name] : '';
    return null;
  });

  const [validated, setValidated] = React.useState(false);
  const [fields, setFields ] = React.useState(fieldsDefault);
  const [ feedback, setFeedback] = React.useState(null);
  const [validationMessages, setValidationMessages] = React.useState({});
  const [ processing, setProcessing] = React.useState(false);

  const handleChangeField = (name) => (evt) => {
    fields[name] = evt.target.value || '';
    setFields({...fields});
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    setProcessing(true);
    event.preventDefault();
    event.stopPropagation();
    const formData = {...fields, "form-name": name};
    const formDataObj = new FormData(form)
    const messages = Array
      .from(formDataObj.keys())
      .reduce((acc, key) => {
        acc[key] = form.elements[key].validationMessage
        return acc
      }, {})
    setValidationMessages(messages);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      //Send value via netlify
      const requestOptions = {
        url: location.pathname,
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: qs.stringify(formData),
      }
      const response = await fetch(location.pathname, requestOptions);
      if(response.status === 200){
        setFeedback({status: 'success', message: success});
        form.reset()
        setValidated(true);
        setProcessing(false);
        return false;
      }
      setFeedback({status: 'danger', message: error});
      setProcessing(false);
      return false;
    }
  };
  return (
    <ContactContainer>
      <ContactAddress>
        <h3>Coop’ de solidarité 25e heure</h3>
        <p>la visibilité des projets responsables québécois</p>
        <p><strong>Cell :</strong> +1 (438) 738-9546</p>
        <p>
          <strong>Horaires de contact par téléphone</strong> <br />
          lundi : de 10h30 à 18h <br />
          du mardi au jeudi : de 9h à 18h <br />
          vendredi : de 9h à 12h <br />
        </p>
      </ContactAddress>

      
      <Form className="Contact" method="post" name={name} netlify-honeypot="bot-field" action={pathname} onSubmit={handleSubmit} data-netlify="true">
        <input type="hidden" name="bot-field" />
        {feedback && (<Alert variant={feedback.status}>{feedback.message}</Alert>)}
        {feedback === null || feedback.status !== 'success' ? (
          <React.Fragment>
            {fieldsDefinition.map((field, key) => (
              <React.Fragment key={key}>
                {field.type === 'textarea' ? (
                  <FormGroup>
                  <Label>{field.label}</Label> <br />
                      <TextArea
                          required={field.required}
                          name={field.name}
                          value={fields[field.name]}
                          // invalid={validationMessages[field.name]}
                          onChange={handleChangeField(field.name)}
                          placeholder={field.placeholder}
                        />
                        {/* {field.feedback && (
                          <FormFeedback type={field.feedback.type}>
                            {field.feedback.message}
                          </FormFeedback>
                        )} */}
                </FormGroup>
                ) : (
                  <FormGroup key={key}>
                    <Label>{field.label}</Label> <br />
                        <Input
                            required={field.required}
                            type={field.type}
                            name={field.name}
                            value={fields[field.name]}
                            // invalid={validationMessages[field.name]}
                            onChange={handleChangeField(field.name)}
                            placeholder={field.placeholder}
                          />
                          {/* {field.feedback && (
                            <FormFeedback type={field.feedback.type}>
                              {field.feedback.message}
                            </FormFeedback>
                          )} */}
                  </FormGroup>
                )}
    
              </React.Fragment>
            ))}
         
        <Submit>
          <Button 
            disabled={processing} 
            type="submit" 
            value={submit} 
          >
            {submit}
          </Button>
        </Submit>
        </React.Fragment>
       ) : null }
      </Form>
    </ContactContainer>
  );
};

Contact.defaultProps = {
  formFields: [
    {
      name: 'prenom',
      label: 'Prénom',
      required: true,
      feedback: {
        type: 'invalid',
        message: 'Entrez votre prénom.',
      },
      type: 'text',
      placeholder: 'Prénom'
    },
    {
      name: 'nom',
      label: 'Nom',
      required: true,
      feedback: {
        type: 'invalid',
        message: 'Entrez votre nom.',
      },
      type: 'text',
      placeholder: 'Nom'
    },
    {
      name: 'entreprise',
      label: 'Entreprise',
      type: 'text',
      placeholder: 'Entreprise'
    },
    {
      name: 'courriel',
      label: 'Courriel',
      required: true,
      feedback: {
        type: 'invalid',
        message: 'Entrez votre courriel.',
      },
      type: 'email',
      placeholder: 'Courriel'
    },
    {
      name: 'telephone',
      label: 'Téléphone',
      feedback: {
        type: 'invalid',
        message: 'Entrez votre numéro de téléphone.',
      },
      type: 'phone',
    },
    {
      name: 'objet',
      label: 'Objet',
      required: true,
      feedback: {
        type: 'invalid',
        message: 'Entrez un objet.',
      },
      type: 'text',
      placeholder: 'Objet'
    },
    {
      name: 'message',
      label: 'Message',
      required: true,
      feedback: {
        type: 'invalid',
        message: 'Entrez un message.',
      },
      type: 'textarea',
      placeholder: 'Message'
    },
    
  ],
  success: "Merci pour votre message.",
  error: "Une erreur s'est produite.",
  submit: "Valider",
  name: "Contact"
}

export default Contact;