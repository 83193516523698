
export const asyncLocalStorage = {
  setItem: async function (key, value) {
      await null;
      if(typeof window !== `undefined`)
      return localStorage.setItem(key, value);
  },
  getItem: async function (key) {
      await null;
      if(typeof window !== `undefined`)
      return localStorage.getItem(key);
  }
};