import styled from 'styled-components';

export const GlobalWrapper = styled.div`
`;

export const Main = styled.div`
    margin-top: 8rem;

    ${props => props.theme.breakpoints.small} {
      margin-top: 5rem;
    }

  ${props => props.theme.breakpoints.large} {
    margin-top: 2.875rem;
  }
`;