import React, { FC, ReactElement } from "react"
import parse from "html-react-parser"
import {asyncLocalStorage} from "../../../utils/asyncStorage";
import Toast, { ToastType } from "../Toast";
import { useToastContext } from "../Toast/hook";
import { useModalContext } from "../Modal/hook";
import Button from "../../Button";
import Modal, { ModalType } from "../Modal";
import { CookiesList, ButtonsContainer } from "./styles";
import Checkbox from "../Form/Checkbox";
import { ButtonVariant } from "../../Button/styles";

interface GdprTexts {
  title: string;
  body: ReactElement;
  accept: string;
  moreInfo: string;
};

interface GdprCookie {
  name: string,
  label: string;
  storageKey: string;
  loadFunction: () => void;
};

interface GdprProps {
  texts: GdprTexts;
  cookies: GdprCookie[];
}


export const Gdpr: FC<GdprProps> = ({texts, cookies}) => {
  const [hasConsent, setHasConsent] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [consents, setConsents] = React.useState({});

  const {show: modalOpened,toggleModal, setModalType} = useModalContext();
  const {toggleToast, setToastType} = useToastContext();
  
  const toast = {
    title: texts.title,
    message: texts.body,
    buttonLabel: texts.accept,
    moreInfoLabel: texts.moreInfo,
  };

  const handleClose = () => {
    toggleModal(false);
  }

  const handleOpen = () => {
    setModalType(ModalType.cookies);
    toggleModal(true);
  }
  const handleConsent = (allConsents) => {
    cookies.forEach((c) => {
      asyncLocalStorage.setItem(c.storageKey, allConsents ? true : consents[c.name]);
      if(c.loadFunction){
        c.loadFunction();
      }
    });
    toggleToast(false);
    toggleModal(false);
  }

  const handleChangeCheck = (name) => {
    const values = {...consents};
    values[name] = !consents[name];
    setConsents(values);
  };

  React.useEffect(() => {
    setToastType(ToastType.cookies);
    // setModalType(ModalType.cookies);
    const fetchConsents = async () => {
      return Promise.all([...cookies.map(async(c) => {
        const storedConsent = await asyncLocalStorage.getItem(c.storageKey);
        return [c.name, storedConsent === 'true' ? true : false ];
      })]);
    }
    fetchConsents().then((approuvals) => {
      setHasConsent(cookies.length === approuvals.filter(a => a[1]).length);
      setConsents(Object.fromEntries(approuvals));
      setLoading(false);
    });
  },[cookies]);

  React.useEffect(() => {
    setHasConsent(cookies.length === Object.keys(consents).map(k => consents[k]).filter(a => a).length)
    if(!loading && !hasConsent && Object.keys(consents).length > 0 && !modalOpened){
      toggleToast(true);
    }
  }, [consents, hasConsent, loading]);

  return (
    <React.Fragment>
      <Modal 
        type={ModalType.cookies}
        title={texts.title}
        backdrop
        closeButton
      >
        {texts?.body}
        <CookiesList>
          {cookies.map(c => (
            <Checkbox
              id={c.name}
              key={c.name} 
              label={c.label} 
              onChange={
                () => handleChangeCheck(c.name)
              }
            />
          ))}
        </CookiesList>
        <ButtonsContainer>
          <Button onClick={() => handleConsent(true)}>
              {texts.accept}
          </Button>
        </ButtonsContainer>
      </Modal>
      <Toast title={toast.title} closeButton type={ToastType.cookies}>
          {toast?.message}
          <ButtonsContainer>
            <Button 
              variant={ButtonVariant.secondary}
              onClick={() => {
                handleOpen()
                toggleToast(false);
              }}>{toast.moreInfoLabel}</Button>
            <Button onClick={() => handleConsent(true)}>
              {toast.buttonLabel}
            </Button>
          </ButtonsContainer>
        </Toast>
    </React.Fragment>
  );
}


// Gdpr.defaultProps = {
//   cookies: [
//     {
//       name: 'gtag',
//       label: 'Google Analytics',
//       storageKey: gdprTracking.gtag.optinKey,
//       loadFunction: () => window && window.loadGtag
//     },
//     {
//       name: 'fbq',
//       label: 'Facebook pixel',
//       storageKey: customConfig.facebook.optinKey,
//       loadFunction: () => window && window.loadFbq
//     },
//     {
//       name: 'hotjar',
//       label: 'Hotjar',
//       storageKey: customConfig.hotjar.optinKey,
//       loadFunction: () => window && window.loadHotjar
//     }
//   ],
//   texts: {
//     title: "We use cookies",
//     body: "We use our own and third-party cookies to personalize content and to analyze web traffic. [Read More](/cgv)",
//     accept: "Accept",
//     moreInfo: "More info"
//   },
// }

export default Gdpr;