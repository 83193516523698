import React from "react";
import ReactMarkdown from 'react-markdown';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Button from "../../../Button";
import { MailChimpForm, Title, InputCheckbox } from "./styles";
import config from "../../../../../config.json";
import { FormGroup, Label, Input } from "../../../Core/ContactV1/styles";

export default ({
  onComplete
}) => {
  const [email, setEmail] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [interets, setInterets] = React.useState({
    actus: false,
    projets: false,
    commanditaire: false,
    evenements: false,
    conseils: false,
  });
  const [result, setResult] = React.useState({result: null, msg: null});
  const [validated, setValidated] = React.useState(null);

  const handleChangeInteret = (name) => {
    setInterets((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const fields = {
      FNAME: prenom,
      ...(interets.actus && {'group[218764][8]': '8'}),
      ...(interets.projets && {'group[218764][128]': '128'}),
      ...(interets.commanditaire && {'group[218764][256]': '256'}),
      ...(interets.evenements && {'group[218764][16]': '16'}),
      ...(interets.conseils && {'group[218764][32]': '32'}),
    };
    const res = await addToMailchimp(email,
      fields,
      config.appelProjets.endPoint
      );
    setResult(res);
    if(res.result === 'error'){
      setValidated(false);
    }else{
      setValidated(true);
      setEmail('');
      onComplete && onComplete();
    }
  }
  return (
    <div>
      <Title>{config.appelProjets.title}</Title>
      <MailChimpForm onSubmit={handleSubmit}>
        <FormGroup>
          <Label>
            Prénom *
          </Label>
          <Input 
            type="text" 
            value={prenom} 
            required
            onChange={(evt) => {setPrenom(evt.target.value); setValidated(null)}} 
          />
        </FormGroup>
        <FormGroup>
          <Label>
            Courriel *
          </Label>
          <Input 
            type="text" 
            value={email} 
            required
            onChange={(evt) => {setEmail(evt.target.value); setValidated(null)}} 
          />
          <p>
            {config.appelProjets.emailDescription}
          </p>
        </FormGroup>

        <FormGroup>
          <Label>
            Intérêts
          </Label>
          <FormGroup>
            <InputCheckbox 
              id="actus"
              type="checkbox" 
              value={interets.actus} 
              onChange={(evt) => {handleChangeInteret('actus'); setValidated(null)}} 
              />
            <Label htmlFor="actus">
              Recevoir des actu' de la coop
            </Label>
          </FormGroup>
          <FormGroup>
            <InputCheckbox 
              id="projets"
              type="checkbox" 
              value={interets.projets} 
              onChange={(evt) => {handleChangeInteret('projets'); setValidated(null)}} 
              />
            <Label htmlFor="projets">
              Participer aux appels à projet
            </Label>
          </FormGroup>
          <FormGroup>
            <InputCheckbox 
              id="commanditaire"
              type="checkbox" 
              value={interets.commanditaire} 
              onChange={(evt) => {handleChangeInteret('commanditaire'); setValidated(null)}} 
              />
            <Label htmlFor="commanditaire">
              Devenir commanditaire
            </Label>
          </FormGroup>
          <FormGroup>
            <InputCheckbox 
              id="evenements"
              type="checkbox" 
              value={interets.evenements} 
              onChange={(evt) => {handleChangeInteret('evenements'); setValidated(null)}} 
              />
            <Label htmlFor="evenements">
              Etre invité.e aux événements
            </Label>
          </FormGroup>
          <FormGroup>
            <InputCheckbox 
              id="conseils"
              type="checkbox" 
              value={interets.conseils} 
              onChange={(evt) => {handleChangeInteret('conseils'); setValidated(null)}} 
              />
            <Label htmlFor="conseils">
              Recevoir des conseils de com' responsable
            </Label>
          </FormGroup>
        </FormGroup>
        {!validated && (
          <p style={{color: 'red'}}>
            {result.msg}
          </p>
        )}
        <Button type="submit">S'inscrire</Button>
          <ReactMarkdown>
            {config.appelProjets.endFormDescription}
          </ReactMarkdown>
      </MailChimpForm>
    </div>
  );
}