import React from "react";
import {Link as GatsbyLink} from "gatsby";

export const Link = ({...props}) => {
  const toTmp = props.to;
  const to = (toTmp.charAt(0) !== '/' ) ? `/${toTmp}` : toTmp;
  const linkProps = {
    ...props,
    partiallyActive: true,
    to,
  }
  return (
    <GatsbyLink {...linkProps} />
  )
}

export default Link;