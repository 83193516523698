import React from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Button from "../../../Button";
import { MailChimpForm, Text } from "./styles";

export default () => {
  const [email, setEmail] = React.useState('');
  const [result, setResult] = React.useState({result: null, msg: null});
  const [validated, setValidated] = React.useState(null);
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const res = await addToMailchimp(email);
    setResult(res);
    if(res.result === 'error'){
      setValidated(false);
    }else{
      setValidated(true);
      setEmail('');
      // onComplete && onComplete();
    }
  }
  return (
    <div>
      <Text>Inscrivez-vous à notre infolettre</Text>
      <MailChimpForm onSubmit={handleSubmit} noValidate>
        <input 
          type="text" 
          value={email} 
          onChange={(evt) => {setEmail(evt.target.value); setValidated(null)}} 
        />
        <Button type="submit">Envoyez</Button>
      </MailChimpForm>
    </div>
  );
}