import styled from 'styled-components';


export const Item = styled.div`
  display: flex;
  &:not(:first-child) {
    margin-top: 5rem;
  }
`;

export const PictoContainer = styled.div`
  min-width: 234px;
  display: none;

  ${props => props.theme.breakpoints.large} {
    display: block;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-top: 1rem;
  }
  
  ${props => props.theme.breakpoints.large} {
    margin-left: 8rem;
    width: 800px;
  }
`;


export const ServiceTitle = styled.h4`
  
`;


export const Excerpt = styled.p`
  
`;
export const SubServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2.5rem;
  flex-wrap: wrap;

  a {
    margin-top: 1rem;
  }

  a:not(:last-child) {
    margin-right: 1rem;
  }
`;


