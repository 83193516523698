import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/Core/bio"
import Seo from "../components/Core/seo"
import { getWpConfig } from "../utils/wpConfig"
import { mapPostToBanner } from "../utils/mappers/banner"
import Banner from "../components/Banner"
import { ContentContainer } from "../components/styles"
import { formatDate } from "../utils/dates"
import { mapMembresBlockToCards } from "../utils/mappers/membres"
import { mapServiceBlockToCards } from "../utils/mappers/posts"
import { getRelatedContent } from "../utils/tagBags"
import Cards from "../components/Blocks/Cards"

import Link from "../components/Core/Link";

const RealisationTemplate = ({ data: { previous, next, post } }) => {
  const {dateFormat}  = getWpConfig();
  const { author: {node: author}} = post;
  const bannerData = mapPostToBanner(post);
  const tagBagIds = post?.tagBags?.nodes?.map((n) => n.id);
  const serviceCards = mapServiceBlockToCards(getRelatedContent(tagBagIds, 'services'));
  const membreCards = mapMembresBlockToCards(getRelatedContent(tagBagIds, 'membres'));

  return (
    <React.Fragment>
      <Seo title={post.title} description={post.excerpt} />
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
          >
      <header>
        <Banner 
          title={bannerData.title}
          image={bannerData.image}
          mobileImage={bannerData.mobileImage}
          text={bannerData.text}
          links={bannerData.links}
          date={formatDate(post.date, dateFormat)}
          />
      </header>
        <ContentContainer>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}

        <hr />

        <footer>
          <Bio author={author}/>
        </footer>

        {serviceCards.length > 0 && (
          <React.Fragment>
            <h3>Services associés</h3>
            <Cards cards={serviceCards} />
          </React.Fragment>
        )}
        {membreCards.length > 0 && (
          <React.Fragment>
            <h3>Membres</h3>
            <Cards cards={membreCards} />
          </React.Fragment>
        )}
      </ContentContainer>
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default RealisationTemplate;

export const pageQuery = graphql`
  query RealisationById(
    $id: String!
  ) {
    post: wpRealisation(id: { eq: $id }) {
      ...realisationContent
    }
  }
`