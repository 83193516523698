import styled from 'styled-components';
import Link from '../../../Core/Link';

export const ServicesContainer = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;

  ${props => props.theme.breakpoints.large} {
    padding: 0 16rem;
    flex-direction: row;
  }

  @media screen and (max-width: 100rem) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  background: ${props => props.theme.color.primaryLight};
  & > div {
    ${props => props.theme.breakpoints.large} {
      width: 50%;
      padding: 0 4rem;
    }
    padding: 0 1rem;
    margin: 2rem 0;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InnerIntroContainer = styled.div`
  p {
    margin-top: 2rem;
  }
`;

export const ServicesCards = styled.div`
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ServiceCard = styled(Link)`
  text-decoration: none;
  width: calc(33% - 0.5rem);
  margin-top: 1rem;
  display: block;
  background-color: ${props => props.theme.color.white};
`;

export const ServiceCardInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
  
export const ItemTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  text-align: center;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  padding: 1rem;
`;