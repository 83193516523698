import React from "react";
import { 
  ServicesContainer, 
  IntroContainer, 
  InnerIntroContainer, 
  ServicesCards, 
  ServiceCard, 
  ItemTitle, 
  ServiceCardInner
} from "./styles";
import { PlusBtnLink, PlusBtnLinkContainer } from "../../../styles";
import { Picto } from "../../../Picto";


//TODO : i18n.
export const ServicesBlockComponent = ({title, content, items}) => {
  return (
    <ServicesContainer>
      <IntroContainer>
        <InnerIntroContainer dangerouslySetInnerHTML={{__html: content}} />
        <PlusBtnLinkContainer >
          <PlusBtnLink to="/services">
            Voir tous nos services
          </PlusBtnLink>
        </PlusBtnLinkContainer>
      </IntroContainer>
      <ServicesCards>
        {items.map(item => (
          <ServiceCard key={item.slug} to={item.uri}>
            <ServiceCardInner>
              <Picto id={item.picto} />
              <ItemTitle>{item.title}</ItemTitle>
            </ServiceCardInner>
          </ServiceCard>
        ))}
      </ServicesCards>
    </ServicesContainer>
  );
};