import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {getWpConfig} from "../utils/wpConfig";
import {formatDate} from "../utils/dates";
import Link from "../components/Core/Link";
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/Core/bio"
import Seo from "../components/Core/seo"
import { mapPostToBanner } from "../utils/mappers/banner";
import { ContentContainer } from "../components/styles";
import Banner from "../components/Banner";

const BlogPostTemplate = (args) => {
  const { data: { previous, next, post }} = args;
  const { author: {node: author}} = post;
  const {dateFormat}  = getWpConfig();
  const bannerData = mapPostToBanner(post);
  // 
  // 
  return (
    <React.Fragment>
      <Seo title={post.title} description={post.excerpt} />
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
          >
      <header>
        <Banner 
          title={bannerData.title}
          image={bannerData.image}
          mobileImage={bannerData.mobileImage}
          text={bannerData.text}
          links={bannerData.links}
          date={formatDate(post.date, dateFormat)}
          />
      </header>
        <ContentContainer>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}

        <hr />

        <footer>
          <Bio author={author}/>
        </footer>
      </ContentContainer>
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </React.Fragment>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      ...postContent
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`