import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import parse from "html-react-parser"
import { mapServiceDataToServiceList } from "../../../utils/mappers/services";
import { Item, PictoContainer, Excerpt, SubServicesContainer, ContentContainer, ServiceTitle } from "./styles";
import List from "../../List";
import { Picto } from "../../Picto";
import { BtnLink } from "../../styles";
export default ({}) => {
  //TODDO : i18n
  const data = useStaticQuery(graphql`
  query ServicesListQuery {
    services: allWpCategorieService (
      sort: {fields: description}
      ){
       ...categorieService
    }
  }
    `);

    const items = mapServiceDataToServiceList(data?.services);

    const Items = items.map((item) => (
      <Item>
        <PictoContainer>
          <Picto id={item.picto} />
        </PictoContainer>
        <ContentContainer>
          <ServiceTitle>
            {item.title}
          </ServiceTitle>
          <Excerpt>{item?.excerpt && parse(item.excerpt)}</Excerpt>
          {item.children?.length > 0 && (
            <SubServicesContainer>
              {item.children.map((child) => (
                  <BtnLink key={child.uri} to={child.uri}>
                    {child.title}
                  </BtnLink>
                )
              )}
            </SubServicesContainer>
          )}
        </ContentContainer>
      </Item>
    ));

    // 
  return (
    <List>
      {Items}
    </List>
  );
}
