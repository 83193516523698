import React, { FC } from "react";
import parse from "html-react-parser"
import { ModalBackdrop, ModalContainer, ModalHeader, CloseButton, ModalTitle, ModalBody, ModalContent, ModalWrapper } from "./styles";
import { useModalContext } from "./hook";
import {AiOutlineClose} from "react-icons/ai";

export enum ModalType {
  cookies,
  infolettre,
  appelProjets,
};

interface ModalProps {
  title?: string;
  type: ModalType;
  closeButton?: boolean;
  backdrop?: boolean;
  onHide?: () => void;
}

const Modal: FC<ModalProps> = ({
  title, 
  type,
  backdrop = false, 
  closeButton = false,
  onHide,
  children,
}) => {

  const {show, toggleModal, modalType} = useModalContext();

  const handleCloseModal = () => {
    onHide && onHide();
    toggleModal(false);
  }

  return show && modalType === type && (
    <ModalWrapper>
      <ModalContainer>
        <ModalContent>
          <ModalHeader
            withTitle={title !== undefined}
            >
            {title && (
              <ModalTitle>
                {title}
              </ModalTitle>
            )}
            {closeButton && (
              <CloseButton onClick={handleCloseModal}>
                <AiOutlineClose />
              </CloseButton>
            )}
          </ModalHeader>
          <ModalBody>
              {children}
          </ModalBody>
        </ModalContent>
      </ModalContainer>
      {backdrop === true && (
        <ModalBackdrop />
      )}
    </ModalWrapper>
  );
};

export default Modal;