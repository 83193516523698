import styled from 'styled-components';

interface BannerProps {
  heightRatio: number;
}

export const Container = styled.div`
    min-height: 70vh;
    height: 70vh;
    min-width: 100%;
    position: relative;
    display: flex;
`;

export const BannerContainer = styled.div`
    z-index:-1;
    width: 100%;
    position: absolute;
`;

export const BannerDesktop = styled.div`
    display: none:
    ${props => props.theme.breakpoints.medium} {
        display: block;
    }
`;

export const BannerMobile = styled.div`
    ${props => props.theme.breakpoints.medium} {
        display:none;
    }
`;

export const SubContainer = styled.div`
    display: none;

    ${props => props.theme.breakpoints.large} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 6rem; 
        margin-bottom: 4rem;
        width: 45%;
        margin-top: auto;

        h1 {
            color: var(--color-primary) !important;
            font-size: 1.875rem;
            line-height: 1.2;
        }

    
        p {
            margin-top: 2rem;
            font-size: 1rem;
            color: var(--color-primary);
        }
    }
`;

export const MobileSubContainer = styled.div`
    ${props => props.theme.breakpoints.large} {
        display: none;
    }

    padding: 2rem;
    border-bottom: solid rgba(0,0,0,0.4) 1px;

    h1 {
        color: var(--color-primary) !important;
        font-size: 1.875rem;
        line-height: 1.2;
    }

    p {
        margin-top: 2rem;
        font-size: 1rem;
        color: var(--color-primary);
    }
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 700;
    font-size: 40px;
    color: ${props => props.theme.color.primary};
`;

export const Subtitle = styled.h1`
    font-family: ${props => props.theme.fonts.primary};
    font-weight: bold;
    font-size: 2.5rem;
    color: ${props => props.theme.color.primary};
`;

export const LinksContainer = styled.div`
    margin-top: 3.125rem;
    display: flex;
    flex-wrap: wrap;

    a {
        margin-top: 1rem;
        margin-right: 1rem;
    }
`;