export const colors = {
  main: {
    blue: '#45D7CB',
    yellow: '#FCC03A',
    orange: '#FD491E',
    purple: '#6E66A9',
    black: '#000000',
  },
  light: {
    blue: 'rgba(69, 215, 203, 0.5)',
    yellow: 'gba(255, 176, 0, 0.5)',
    orange: 'rgba(255, 49, 0, 0.5)',
    purple: '#D6DAFF',
    black: 'rgba(0, 0, 0, 0.5)',
  },
  lighter: {
    blue: 'rgba(69, 215, 203, 0.25)',
    yellow: 'rgba(252, 192, 58, 0.25)',
    orange: 'rgba(253, 73, 30, 0.25)',
    purple: 'rgba(214, 218, 255, 0.5)',
    black: 'gba(0, 0, 0, 0.25)',
  }
};

export const fontSize = 16;

const ptr = (px) => {
  var x = fontSize || 16,
      rem = (1/x * px) + 'rem';
  return rem;
};

const convertTypography2Rem = (typography) => ({
  fontSize: `${ptr(typography.fontSize)}rem`,
  lineHeight: `${ptr(typography.lineHeight)}rem`,
  fontWeight: typography.fontWeight,
})

export const typography = {
  font: 'Sinkin sans', //TODO Add fallbacks
  fontSize: `${fontSize}`,
  desktop: {
    headers: {
      h1: convertTypography2Rem({
        fontSize: 80,
        lineHeight: 95,
        fontWeight: 'bold',
      }),
      h2: convertTypography2Rem({
        fontSize: 55,
        lineHeight: 65,
        fontWeight: 'bold',
      }),
      h3: convertTypography2Rem({
        fontSize: 30,
        lineHeight: 40,
        fontWeight: '600',
      }),
      h4: convertTypography2Rem({//Titre pastilles services
        fontSize: 16,
        lineHeight: 20,
        fontWeight: 'bold',
      }),
      h5: convertTypography2Rem({//Titre pastilles articles
        fontSize: 11,
        lineHeight: 14,
        fontWeight: '600',
      }),
    },
    texts: {
      default: convertTypography2Rem({ //Texte courant niveau 1
        fontSize: 11,
        lineHeight: 20,
        fontWeight: 'normal',
      }),
      large: convertTypography2Rem({ //Texte courant niveau 2
        fontSize: 16,
        lineHeight: 26,
        fontWeight: '500',
      }),
      smallBold: convertTypography2Rem({ //Texte courant footer
        fontSize: 8,
        lineHeight: 10,
        fontWeight: '600',
      }),
      small: convertTypography2Rem({ //Texte courant pastilles articles
        fontSize: 8,
        lineHeight: 14,
        fontWeight: 'normal',
      }),
      ['x-small']: convertTypography2Rem({ //Texte date + auteur pastilles articles
        fontSize: 7,
        lineHeight: 12,
        fontWeight: 'normal',
      }),
    },
    buttons: {
      default: convertTypography2Rem({ //Texte bouton
        fontSize: 12,
        lineHeight: 'inherit',
        fontWeight: 'bold',
      }),
      small: convertTypography2Rem({ //Texte bouton +
        fontSize: 11,
        lineHeight: 'inherit',
        fontWeight: '500',
      }),
      ['x-small']: convertTypography2Rem({ //Texte bouton (footer)
        fontSize: 8,
        lineHeight: 'inherit',
        fontWeight: '500',
      }),
    }
  },
  mobile: {
    headers: {
      h1: convertTypography2Rem({
        fontSize: 40,
        lineHeight: 47,
        fontWeight: 'bold',
      }),
      h2: convertTypography2Rem({
        fontSize: 30,
        lineHeight: 40,
        fontWeight: 'bold',
      }),
      h3: convertTypography2Rem({
        fontSize: 20,
        lineHeight: 30,
        fontWeight: '600',
      }),
      h4: convertTypography2Rem({//Titre pastilles services
        fontSize: 14,
        lineHeight: 18,
        fontWeight: 'bold',
      }),
      h5: convertTypography2Rem({//Titre pastilles articles
        fontSize: 11,
        lineHeight: 14,
        fontWeight: '600',
      }),
    },
    texts: {
      default: convertTypography2Rem({ //Texte courant niveau 1
        fontSize: 11,
        lineHeight: 20,
        fontWeight: 'normal',
      }),
      large: convertTypography2Rem({ //Texte courant niveau 2
        fontSize: 14,
        lineHeight: 24,
        fontWeight: '500',
      }),
      smallBold: convertTypography2Rem({ //Texte courant footer
        fontSize: 8,
        lineHeight: 10,
        fontWeight: '600',
      }),
      small: convertTypography2Rem({ //Texte courant pastilles articles
        fontSize: 8,
        lineHeight: 14,
        fontWeight: 'normal',
      }),
      ['x-small']: convertTypography2Rem({ //Texte date + auteur pastilles articles
        fontSize: 7,
        lineHeight: 12,
        fontWeight: 'normal',
      }),
    },
    buttons: {
      default: convertTypography2Rem({ //Texte bouton
        fontSize: 12,
        lineHeight: 'inherit',
        fontWeight: 'bold',
      }),
      small: convertTypography2Rem({ //Texte bouton +
        fontSize: 11,
        lineHeight: 'inherit',
        fontWeight: '500',
      }),
      ['x-small']: convertTypography2Rem({ //Texte bouton (footer)
        fontSize: 8,
        lineHeight: 'inherit',
        fontWeight: '500',
      }),
    }
  }
}
