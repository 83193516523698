import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { CardsWrapper } from "./styles";
import CardComponent from "./Card";

export type ImageWithAlt = {
  image: IGatsbyImageData;
  alt: string;
}

export type Card = {
  excerpt: string;
  featuredImage: ImageWithAlt; 
  title: string;
  uri: string;
  categorie?: string;
  author?: string;
  date?: string;
}

type CardsProps = {
  cards: Card[];
}

const Cards = ({cards} : CardsProps) => {
  return (
    <CardsWrapper className='cards-wrapper'>
      {(cards || []).map(card => (
        <CardComponent key={card.uri} {...card} />
      ))}
    </CardsWrapper>
  );
};

export default Cards;