import { typography, colors } from "./normsGuide";


export const theme = {
  ...typography,
  color: {
    primary: "var(--color-primary)",
    primaryLight: "#F3F4FF",
    black: colors.main.black,
    white: "#FFF",
    secondary: colors.main.yellow,
    secondaryOrange: colors.main.orange,
    danger: "#FF4700",
    backgroundLight: "rgba(214, 218, 255, 0.3)",
    ...colors,
  },
  fonts: {
    primary: typography.font,
    secondary: typography.font,
  },
  radius: {
    xsmall: '0.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
    xlarge: '5rem',
  },
  size: {
    xsmall: '0.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
    xlarge: '5rem',
  },
  breakpoints: {
    xsmall: '@media screen and (max-width: 31.24rem)',
    small: '@media screen and (min-width: 31.25rem)',
    medium: '@media screen and (min-width: 40rem)',
    large: '@media screen and (min-width: 60rem)',
    xlarge: '@media screen and (min-width: 100rem)'
  },
  space: {
    xsmall: '0.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
    xlarge: '5rem',
  }
};