import React from "react"
import { graphql, PageProps } from "gatsby";
import parse from "html-react-parser"
import Cards from "../components/Blocks/Cards";
import { getImage } from "gatsby-plugin-image";
import Banner from "../components/Banner";
import StepsCards from "../components/StepsCards";
import { getTitle } from "../utils/text";
import { mapPageDataToBanner } from "../utils/mappers/banner";
import { mapServiceDataToItems } from "../utils/mappers/services";
import { ServicesBlockComponent } from "../components/domain/Index/Services";
import { mapPostsBlockToCards, mapRealisationBlockToCards } from "../utils/mappers/posts";
import { BtnLink, ContentContainer } from "../components/styles";
import Seo from "../components/Core/seo";
import { getWpConfig } from "../utils/wpConfig";
import { BtnLinkHomeCardContainer, HomeCardsWrapper, TitleHomeCard } from "../components/Blocks/Cards/styles";

export default function Home({data}: PageProps ) {
  const {posts: {edges: posts}, realisations:{edges: realisations}, homepage: {title, content, ...homepageData}, services} = data as any;
  const config = getWpConfig();
  const {title: siteTitle} = config;
  // 
  
  const blogPosts = [
    ...mapPostsBlockToCards(posts.map(edge => edge.node)),
    ...mapRealisationBlockToCards(realisations.map(edge => edge.node))
  ].sort((a, b) => (a.date > b.date) ? -1:1).slice(0,3);

  //Build Step cards data
  
  const stepCardsData = [
    {
      title: getTitle(homepageData.front_pageFields?.html1),
      content: homepageData.front_pageFields?.html1,
      picto: homepageData.front_pageFields?.picto1,
      featuredImage: getImage(homepageData.front_pageFields?.featured1?.localFile?.childImageSharp),
    },
    {
      title: getTitle(homepageData.front_pageFields?.html2),
      content: homepageData.front_pageFields?.html2,
      picto: homepageData.front_pageFields?.picto2,
      featuredImage: getImage(homepageData.front_pageFields?.featured2?.localFile?.childImageSharp),
    },
    {
      title: getTitle(homepageData.front_pageFields?.html3),
      content: homepageData.front_pageFields?.html3,
      picto: homepageData.front_pageFields?.picto3,
      featuredImage: getImage(homepageData.front_pageFields?.featured3?.localFile?.childImageSharp),
    },
    {
      title: getTitle(homepageData.front_pageFields?.html4),
      content: homepageData.front_pageFields?.html4,
      picto: homepageData.front_pageFields?.picto4,
      featuredImage: getImage(homepageData.front_pageFields?.featured4?.localFile?.childImageSharp),
    },
  ];
  const bannerData = mapPageDataToBanner({title, ...homepageData});

  const servicesData = {
    title: getTitle(homepageData.front_pageFields?.introservices),
    content: homepageData.front_pageFields?.introservices,
    items: mapServiceDataToItems(services),
  };
  return (
    <React.Fragment>
      <Seo title={siteTitle}/>
      <Banner {...bannerData} />
      <ServicesBlockComponent {...servicesData} />
      <ContentContainer className='mission'>
        {parse(content)}
      </ContentContainer>
      <StepsCards title={"La plateforme en 4 étapes"} steps={stepCardsData} />
      {/* <CardBtn title={title} text={text} exercpt={expercpt} /> */}
      <HomeCardsWrapper>
        <TitleHomeCard>
          Nos derniers articles
        </TitleHomeCard>
        <Cards cards={blogPosts} />

        <BtnLinkHomeCardContainer>
          <BtnLink className='center secondaryOrange' key='blogue' activeClassName="active" to='/blogue'>
            EN VOIR PLUS
          </BtnLink>
        </BtnLinkHomeCardContainer>
      </HomeCardsWrapper>
    </React.Fragment>
  );
}


export const pageQuery = graphql`
  query HomePageQuery {
    posts: allWpPost(
      limit: 3
      sort: {fields: [date], order: DESC}
      filter: {language: {code: {eq: FR}}, status: {eq: "publish"}}
    ) {
      edges {
        node {
          ...lastPostsBlock
        }
      }
    }
    realisations: allWpRealisation(
      limit: 3
      sort: {fields: [date], order: DESC}
      filter: {language: {code: {eq: FR}}, status: {eq: "publish"}}
    ) {        
      edges {
        node {
          ...realisationsBlock
        }
      }
    }
    homepage: wpPage(isFrontPage: {eq: true}) {
      ...pageContent
      ...frontPageContent
    }
    services: allWpCategorieService {
      edges {
        node {
          ...categorieServiceBlock
        }
      }
    }
  }
`