import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { mapPostsBlockToCards, mapRealisationBlockToCards } from "../../../utils/mappers/posts";
import { ContentContainer } from "../../styles";
import Cards from "../../Blocks/Cards";

import config from "../../../../config.json";
const {categoriesBlogue} = config;

export default ({location}) => {
  const data = useStaticQuery(graphql`
    query BlogueListQuery {
      posts: allWpPost(
        filter: {language: {code: {eq: FR}}, status: {eq: "publish"}}
        sort: {fields: date, order: DESC}
      ){
        edges {
          node {
            ...lastPostsBlock
          }
        }
      }
      realisations: allWpRealisation(
        filter: {language: {code: {eq: FR}}, status: {eq: "publish"}}
        sort: {fields: date, order: DESC}
      ){
        edges {
          node {
            ...realisationsBlock  
          }
        }
      }
    }
  `); 

  const mappedData = [
    ...mapRealisationBlockToCards(data?.realisations?.edges?.map((edge) => edge.node)),
    ...mapPostsBlockToCards(data?.posts.edges?.map((edge) => edge.node)),
  ].sort((a, b) => (a.categorie > b.categorie ? 1 : -1));
  const categorizedData = {};
  
  mappedData.map(item => {
    if(!categorizedData[item.categorie]){
      categorizedData[item.categorie] = [];
    }
    categorizedData[item.categorie].push(item);
  });
  return (
    <ContentContainer>
      {categoriesBlogue.map(cat => (
        <section id={cat.name} key={cat.name}>
          {categorizedData[cat.name] && categorizedData[cat.name].length > 0 && (
            <React.Fragment>
              <h2>{cat.title}</h2>
              <Cards cards={categorizedData[cat.name]} />
            </React.Fragment>
          )}
        </section>
      ))}
    </ContentContainer>
      
  );
}