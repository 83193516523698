import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import {CardWrapper, Content, CardHeader, CardBody, CardInnerWrapper, ImageCard, PictoWrapper } from "./styles";
import {StepCard} from "../index";
import { Picto } from "../../Picto";


const StepCardComponent = ({title, content, featuredImage, picto}: StepCard) => {

  return (
    <CardWrapper> 
        <PictoWrapper>
          <Picto id={picto} />
        </PictoWrapper>
        <CardInnerWrapper>
          <Content dangerouslySetInnerHTML={{__html: content}} />
          <ImageCard>
              {featuredImage && (<GatsbyImage image={featuredImage} alt={title}/>)}
          </ImageCard>
        </CardInnerWrapper>
    </CardWrapper>
  );
};

export default StepCardComponent;