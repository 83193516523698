import React from "react";
import Button from "../../../../Button";
import { ButtonVariant } from "../../../../Button/styles";
import { useModalContext } from "../../../../Core/Modal/hook";
import Modal, {ModalType} from "../../../../Core/Modal";
import { StaticImage } from "gatsby-plugin-image";
import AppelProjets from "../../AppelProjets";
import { ContentContainer } from "./styles";

export default () => {

  const {show: modalOpened,toggleModal, setModalType} = useModalContext();
  
  const handleOpen = () => {
    setModalType(ModalType.infolettre);
    toggleModal(true);
  }

  const handleOnComplete = () => {
    toggleModal(false);
  }

  return (
    <>
      <Modal
        type={ModalType.infolettre}
        backdrop
        closeButton
      >
        <ContentContainer>
          <StaticImage 
            src="../../../../../assets/images/facebook.jpg" 
            alt="" 
            width={800}
          />
          <AppelProjets onComplete={handleOnComplete} />
        </ContentContainer>
      </Modal>
      <Button variant={ButtonVariant.secondary} onClick={handleOpen}>
        Inscription à l'infolettre
      </Button>
    </>
  );
}