import memoize from "fast-memoize";
import {useStaticQuery, graphql } from "gatsby";
const gWC = () => {
  const settings = useStaticQuery(graphql`
    query SettingsQuery {
      wp {
        generalSettings {
          description
          title
          language
          dateFormat
          timezone
          timeFormat
          url
          startOfWeek
        }
        languages {
          code
          homeUrl
          locale
          name
        }
      }
    }
  `);
  //Convert dateFormat to date-fns
  const dateFormat = settings?.wp?.generalSettings?.dateFormat.replace('j', 'd').replace('F', 'LLLL');
  return {
    ...settings?.wp?.generalSettings,
    language: settings?.wp?.languages.find(a => a.locale === settings?.wp?.generalSettings.language),
    languages: settings?.wp?.languages,
    dateFormat,
  };
};

export const getWpConfig = memoize(gWC);