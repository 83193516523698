import React from "react";
import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
`;

export const CheckboxLabel = styled.label`

`;

const CB = styled.input`
  max-width: 50px;
`;

export const Checkbox = (props) => (
  <CB type="checkbox" {...props} />
)
