import styled from 'styled-components';

export enum ButtonVariant {
    primary,
    secondary
};

type ButtonProps = {
    variant: ButtonVariant;
    theme: any;
};

export const PrimaryButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 8px;
    border: 2px solid ${props => props.theme.color.primary};
    border-radius: 4px;
    cursor: pointer;

    font-family: ${props => props.theme.fonts.primary};
    font-weight: 900;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: ${(props: ButtonProps) => {
        switch(props.variant){
            case ButtonVariant.secondary:
                return props.theme.color.primary;
            default: 
                return props.theme.color.white;

    }}};
    background: ${(props: ButtonProps) => {
        switch(props.variant){
            case ButtonVariant.secondary:
                return props.theme.color.primaryLight;
            default: 
                return props.theme.color.primary;

    }}};

    &:hover {
        background: ${props => props.theme.color.white};;
        color: ${props => props.theme.color.primary};
    }

    &:active {
        background: ${props => props.theme.color.white};;
        color: ${props => props.theme.color.primary};
    }
`;