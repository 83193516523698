import React from "react"
import Nav from "./Nav";

const Header = ({isHomePage, title, description}) => {
  return (
    <header className="global-header">
    <Nav title={title} />
    </header>
  );
};

export default React.memo(Header);