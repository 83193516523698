import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Seo from "../components/Core/seo"
import Banner from "../components/Banner"
import { mapServiceToBanner } from "../utils/mappers/banner"
import { ContentContainer } from "../components/styles"
import { getRelatedContent } from "../utils/tagBags"
import { mapRealisationBlockToCards } from "../utils/mappers/posts"
import { mapMembresBlockToCards } from "../utils/mappers/membres"
import Cards from "../components/Blocks/Cards"

const MembreTemplate = ({ data: { service } }) => {
  const bannerData = mapServiceToBanner(service);
  const tagBagIds = service?.tagBags?.nodes?.map((n) => n.id);
  const membresCards = mapMembresBlockToCards(getRelatedContent(tagBagIds, 'membres'));
  const realisationCards = mapRealisationBlockToCards(getRelatedContent(tagBagIds, 'realisations'));
    
  return (
    <React.Fragment>
      <Seo title={service.title} description={service.excerpt} />
      <header>
        <Banner 
          title={bannerData.title}
          image={bannerData.image}
          mobileImage={bannerData.mobileImage}
          text={bannerData.text}
          links={bannerData.links}
        />
      </header>
      <ContentContainer>
        {!!service.content && (
          <section itemProp="serviceBody">{parse(service.content)}</section>
        )}
        {realisationCards.length > 0 && (
          <React.Fragment>
            <h3>Réalisations associées</h3>
            <Cards cards={realisationCards} />
          </React.Fragment>
        )}
        {membresCards.length > 0 && (
          <React.Fragment>
            <h3>Membres associé.e.s</h3>
            <Cards cards={membresCards} />
          </React.Fragment>
        )}
      </ContentContainer>
    </React.Fragment>
  )
}

export default MembreTemplate;

export const pageQuery = graphql`
  query ServiceById(
    $id: String!
  ) {
    service: wpService(id: { eq: $id }) {
      ...serviceContent
    }
  }
`