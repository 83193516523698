import { getImage } from "gatsby-plugin-image";
import { Card } from "../../components/Blocks/Cards";
import memoize from "fast-memoize";

const mapNode = (node) => ({
  ...node,
  author: node?.author?.node?.name,
  featuredImage: {
    image: getImage(node.featuredImage?.node?.localFile?.childImageSharp),
    alt: node.featuredImage?.node?.altText,
  }
})

const mapPost = memoize((node) => (
  {
    ...mapNode(node),
    categorie: node?.postFields?.categorie,
  }
));

const mapRealisation = memoize((node) => (
  {
    ...mapNode(node),
    categorie: 'realisations',
  }
));

const mapService = memoize((node) => (
  {
    ...node,
    // author: node?.author?.node?.name,
    featuredImage: {
      image: getImage(node.featuredImage?.node?.localFile?.childImageSharp),
      alt: node.featuredImage?.node?.altText,
    }
  }
));

export const mapPostsBlockToCards = (posts): Card[] => (
  posts.map(mapPost)
);

export const mapRealisationBlockToCards = (posts): Card[] => (
  posts.map(mapRealisation)
);

export const mapServiceBlockToCards = (posts): Card[] => (
  posts.map(mapService)
);