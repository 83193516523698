import React, {useState, FC} from "react";
import { ToastType } from "./index";
import { Helmet } from "react-helmet";

interface ToastContextInterface {
  show: boolean;
  toggleToast?: (open: boolean) => void;
  setToastType?: (type: ToastType) => void;
  toastType?: ToastType;
}

const defaultState = {
  show: false,
};

export const ToastContext = React.createContext<ToastContextInterface>(defaultState);

export const ToastProvider: FC = ({children}) => {
  const [show, setShow] = useState(defaultState.show);
  const [type, setToastType] = useState<ToastType>(null);

  const toggleToast = (open: boolean) => {
    if(open !== undefined){
      setShow(open);
    }else{
      setShow(!show);
    }
  }

  return (
    <ToastContext.Provider
      value={{
        show,
        toggleToast,
        setToastType,
        toastType: type,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}