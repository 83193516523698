import styled from 'styled-components';

export const Text = styled.div`
font-family: ${props => props.theme.fonts.primary};
font-weight: 300;
font-size: 12px;
color: ${props => props.theme.color.primary};
text-decoration: none;
`;


export const MailChimpForm = styled.form`
display: flex;
margin-top: 0.5rem;

button {
    margin-left: 0.5rem;
}
`;