import styled from 'styled-components';

export const Title = styled.h3`
  color: red;
  text-align: center;
  font-weight: normal;
  margin: 2rem 0 0;
`;

export const Text = styled.div`
font-family: ${props => props.theme.fonts.primary};
font-weight: 300;
font-size: 12px;
color: ${props => props.theme.color.primary};
text-decoration: none;
`;

export const InputCheckbox = styled.input`
    float: left;
    width: auto !important;
    margin-right: 1rem;
    transform: scale(1.5);
    border: 1px solid black;
`;

export const MailChimpForm = styled.form`
display: flex;
flex-direction: column;
margin-top: 0.5rem;
max-width: 40rem;
padding: 0 2rem;

button {
    margin-left: 0.5rem;
}
`;