import React from "react";

import Icon25EHeureEstimeTonBesoin from "../../assets/icons/icon-25e-heure-estime-ton-besoin.svg";
import IconFinanceTonProjet from "../../assets/icons/finance-ton-projet.svg";
import IconIdentiteVisuelle from "../../assets/icons/identite-visuelle.svg"
import IconNotoriete from "../../assets/icons/notoriete.svg"
import IconRedaction from "../../assets/icons/redaction.svg"
import IconSoumettreUnProjet from "../../assets/icons/soumettre-un-projet.svg"
import IconMediasSociaux from "../../assets/icons/medias-sociaux.svg"
import IconObtenezPlusDeVisibilite from "../../assets/icons/obtenez-plus-de-visibilite.svg"
import IconSiteWeb from "../../assets/icons/site-web.svg"
import IconStrategie from "../../assets/icons/strategie.svg"
import { PictoContainer } from "./styles";


const mappedPictos = {
  "25e-heure-estime-ton-besoin" : Icon25EHeureEstimeTonBesoin,
  "finance-ton-projet" : IconFinanceTonProjet,
  "identite-visuelle" : IconIdentiteVisuelle,
  "notoriete" : IconNotoriete,
  "redaction" : IconRedaction,
  "soumettre-un-projet" : IconSoumettreUnProjet,
  "medias-sociaux" : IconMediasSociaux,
  "obtenez-plus-de-visibilite" : IconObtenezPlusDeVisibilite,
  "site-web" : IconSiteWeb,
  "strategie" : IconStrategie,
};


const pictoIds = Object.keys(mappedPictos);

export type PictoType = typeof pictoIds[number];

export const Picto = ({id}) => {
  const PictoSvg = ({id}) => mappedPictos[id]({
    ...mappedPictos[id].defaultProps
  });
  return (
    <PictoContainer>
      <PictoSvg id={id} />    
    </PictoContainer>
  );
};