import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Services from "../components/Pages/Services";

import Seo from "../components/Core/seo"
import { mapPageDataToBanner } from "../utils/mappers/banner";
import Banner from "../components/Banner";
import { ContentContainer } from "../components/styles";
import Members from "../components/Pages/Members";
import Posts from "../components/Pages/Posts";

import config from "../../config.json";
import Contact from "../components/Pages/Contact";
import Form from "../components/domain/Newsletter/ModalAppelProjets/Form";
const {categoriesBlogue} = config;

const PageTemplate = ({ data: { post }, location}) => {
  const template = post?.pageFields?.pagetemplate;
  //TODO : parent
  const bannerData = {
    ...mapPageDataToBanner(post),
    ...(template === 'blogue') && {anchors: categoriesBlogue}
  };

  const renderContent = (tpl) => {
    switch(tpl){
      case 'services':
        return (<Services />);
      case 'membres':
        return (<Members />);
      case 'blogue':
        return (<Posts location={location} />);
      // case 'soumettre-projet': 
      //   return (<Form />);
    }
  }

  return (
    <React.Fragment>
      <Seo title={post.title}/>
      {(bannerData.mobileImage.image || bannerData.image.image) && <Banner {...bannerData} />}
      {post.content && <ContentContainer className={`${!!template ? template: 'default'}`}>
        {parse(post.content)}
      </ContentContainer>}
      {renderContent(template)}
      {template === 'contact' && (
        <Contact location={location}/>
      )}
    </React.Fragment>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    $id: String!
  ) {
    post: wpPage(id: { eq: $id }) {
      ...pageContent
      parent: wpParent {
        node {
          ...parentContent
        }
      }
    }
  }
`