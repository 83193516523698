import React from "react"
import { getMembreFromUserId } from "../../utils/contentLinks";
import Link from "./Link";

const Bio = ({author}) => {
  const membre = getMembreFromUserId(author?.id);
  const avatarUrl = author?.avatar?.url
  return (
    <div className="bio">
      {avatarUrl && (
        <img
          alt={author?.firstName || ``}
          className="bio-avatar"
          src={avatarUrl}
        />
      )}
      {author?.firstName && (
        <p>
          Écrit par <strong>
            {membre?.uri ? (
              <Link to={membre.uri}>
                {author.firstName}
              </Link>
            ) : (
              <React.Fragment>
                {author.firstName} 
              </React.Fragment>
            )}
          </strong>
          {` `}
          {author?.description || null}
          {` `}
          {/* {author?.twitter && (
            <a href={`https://twitter.com/${author?.twitter || ``}`}>
              You should follow them on Twitter
            </a>
          )} */}
        </p>
      )}
    </div>
  )
}

export default Bio
