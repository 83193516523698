import styled from 'styled-components';
import Link from '../../../Core/Link';

export const CardWrapper = styled.div`
  display: flex;
  width: 344px;
  margin-top: 1.5rem;
  margin-right: 2rem;

  flex-direction: column;
  drop-shadow(4px 4px 49px rgba(99, 95, 192, 0.2));
`;

export const CardLink = styled(Link)`
  box-shadow: 3px 3px 8px hsl(0, 0%, 70%);
  transition: all 0.4s;
  text-decoration: none;

  height: 450px;
  min-height: 450px;
`;

export const CardAuthorWrapper = styled.div`
  display: flex;
  font-family: Catamaran;
  font-weight: 500;
  font-size: 9px;
  color: #4E4E4E;
  margin-top: 1rem;
`;

export const CardHeader = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-weight: 700;
  font-size: 16px;
  color: #1D1D1B;
  margin-top: 1rem;
`;

export const CardBody = styled.div`
  font-family: Catamaran;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #959595;
  margin-top: 1rem;
`;

export const CardContainer = styled.div`
  padding: 1rem;
`;
