import React, {useState, FC, Dispatch, ReactComponentElement, SetStateAction} from "react";
import { ModalType } from ".";
import { Helmet } from "react-helmet";

interface ModalContextInterface {
  show: boolean;
  toggleModal?: (open: boolean) => void;
  setModalType?: (type: ModalType) => void;
  modalType?: ModalType;
}

const defaultState = {
  show: false,
};

export const ModalContext = React.createContext<ModalContextInterface>(defaultState);

export const ModalProvider: FC = ({children}) => {
  const [show, setShow] = useState(defaultState.show);
  const [type, setModalType] = useState<ModalType>(null);

  const toggleModal = (open) => {
    if(open !== undefined){
      setShow(open);
    }else{
      setShow(!show);
    }
  }

  return (
    <ModalContext.Provider
      value={{
        show,
        toggleModal,
        setModalType,
        modalType: type,
      }}
    >
      {show && (<Helmet
          htmlAttributes={{
              class: 'modal-oppened'
          }}
      />)}
      {children}
    </ModalContext.Provider>
  );
}