import styled from 'styled-components';

export const ToastContainer = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  padding: ${props => props.theme.space.xsmall};
  background: ${props => props.theme.color.primaryLight};
  border-top: 1px solid ${props => props.theme.color.primary};
  border-right: 1px solid ${props => props.theme.color.primary};
  ${props => props.theme.breakpoints.medium} {
    border-radius: 0 ${props => props.theme.radius.small} 0 0;
  } 
`;

export const ToastTitle = styled.h2`
  font-size: ${props => props.theme.size.small};
`;

export const ToastHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const ToastBody = styled.div`
  
`;