import React from "react"
import { graphql } from "gatsby"
import Link from "../components/Core/Link";
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
// import "../css/@wordpress/block-library/build-style/style.css"
// import "../css/@wordpress/block-library/build-style/theme.css"

import Bio from "../components/Core/bio"
import Seo from "../components/Core/seo"
import { ContentContainer } from "../components/styles"
import Banner from "../components/Banner"
import { mapMembreToBanner } from "../utils/mappers/banner"
import { getRelatedContent } from "../utils/tagBags"
import { mapServiceBlockToCards, mapRealisationBlockToCards } from "../utils/mappers/posts"
import Cards from "../components/Blocks/Cards"

const MembreTemplate = ({ data: { membre } }) => {
  
  const bannerData = mapMembreToBanner(membre);
  const tagBagIds = membre?.tagBags?.nodes?.map((n) => n.id);
  const serviceCards = mapServiceBlockToCards(getRelatedContent(tagBagIds, 'services'));
  const realisationCards = mapRealisationBlockToCards(getRelatedContent(tagBagIds, 'realisations'));
  return (
    <React.Fragment>
      <Seo title={membre.title} description={membre.excerpt} />
      <header>
        <Banner {...bannerData} />
      </header>
      <ContentContainer>
        {!!membre.content && (
          <section itemProp="articleBody">{parse(membre.content)}</section>
        )}
        {realisationCards.length > 0 && (
          <React.Fragment>
            <h3>Réalisations associées</h3>
            <Cards cards={realisationCards} />
          </React.Fragment>
        )}
        {serviceCards.length > 0 && (
          <React.Fragment>
            <h3>Services associés</h3>
            <Cards cards={serviceCards} />
          </React.Fragment>
        )}
      </ContentContainer>
    </React.Fragment>
  )
}

export default MembreTemplate;

export const pageQuery = graphql`
  query MembreById(
    $id: String!
  ) {
    membre: wpMembre(id: { eq: $id }) {
      ...membreContent
    }
  }
`