import React from "react";
import {StaticImage} from "gatsby-plugin-image";

export default ({title}) => (
  <StaticImage
    src="../../../../content/assets/25eHeure_Logo_Final_-01.png"
    alt={`Logo ${title}`}
    placeholder="blurred"
    layout="fixed"
    width={140}
    height={40}
  />
);