import styled from 'styled-components';

export const CardsWrapper = styled.div`
  display: flex;
  padding: 2rem 0rem;
  flex-wrap: wrap;

  ${props => props.theme.breakpoints.large} {
    padding: 4rem 0rem;
  }
`;

export const HomeCardsWrapper = styled.div`
  padding: 2rem;

  ${props => props.theme.breakpoints.large} {
    padding: 4rem 16rem;
  }

  .cards-wrapper {
    justify-content: space-around;
  }

  .card-wrapper {
    margin-right: 0 !important;
  }
`;

export const TitleHomeCard = styled.h2`
  text-align: center;
  color: ${props => props.theme.color.secondaryOrange};
`;


export const BtnLinkHomeCardContainer = styled.div`
  text-align: center;
`;
