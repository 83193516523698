import memoize from "fast-memoize";
import {useStaticQuery, graphql } from "gatsby";


const gUTM = () => {
  const {allWpUser: {nodes: membres}} = useStaticQuery(graphql`
  query allMembresUsers{
    allWpUser {
      nodes {
        id
        membres {
          nodes {
            title
            uri
          }
        }
      }
    }
  }
  `);

  const users = membres.map((data) => {
    const membre = data?.membres?.nodes?.pop();
    return {
      id: data?.id,
      uri: membre?.uri,
      title: membre?.title,
    }
  });
  return users;
}

export const getUsersToMembres = memoize(gUTM);

export const getMembreFromUserId = (userId) => {
  const users = getUsersToMembres();
  return users.find((u) => u.id === userId);
}