import styled from 'styled-components';

export const StepsCardsWrapper  = styled.div`
  padding: 2rem;
  background-color: ${props => props.theme.color.backgroundLight};

  ${props => props.theme.breakpoints.large} {
    padding: 4rem 16rem;
  }
`;

export const StepsCardsTitle  = styled.h2`
  margin-bottom: 4rem;
  text-align: center;
`;