import styled from 'styled-components';

export const CookiesList = styled.div`

`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  gap: ${props => props.theme.space.xsmall}; 
  padding: ${props => props.theme.space.xsmall};
`;