import { getImage } from "gatsby-plugin-image";
import { Card } from "../../components/Blocks/Cards";
import memoize from "fast-memoize";

const mapBlockToCard = memoize((node) => ({
  ...node,
  featuredImage: {
    image: getImage(node.featuredImage?.node?.localFile?.childImageSharp),
    alt: node.featuredImage?.node?.altText,
  }
}));

export const mapMembresBlockToCards = (membresBlocks): Card[] => (
  membresBlocks.map(mapBlockToCard)
);