import React from "react";
import styled from 'styled-components';

export const Form = styled.form`
  width: 100%;
  padding: 2rem;
`;

const AlertDiv = styled.div`

`;

export const Alert = ({children, variant}) => (
  <AlertDiv className={variant}>
    {children}
  </AlertDiv>
);


export const FormFeedback = styled.div`

`;

export const FormGroup = styled.div`
  margin-top: 1rem;
`;

export const Label = styled.label`
  color: ${props => props.theme.color.primary};
`;

export const Input = styled.input`

`;

export const TextArea = styled.textarea`

`;

export const Submit = styled.div`
  margin-top: 4rem;

  button {
    margin: auto;
  }
`;

export const ContactContainer = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.large} {
    padding: 4rem 16rem;
    flex-direction: row;
  }
`;

export const ContactAddress = styled.div`
  padding: 2rem;

  h3 {
    margin-top: 1rem;
  }

  p {
    margin-top: 1rem;
  }
`;


