import { getTitle } from "../text";
import memoize from "fast-memoize";
import { getServiceImage } from "../services";


export const mapPageDataToBanner = (pageData) => {
  const pageFields = pageData?.pageFields;
  const parent = pageData?.parent?.node;
  return pageFields && {
    ...(pageFields?.intropage) ? {
      title: pageData?.title,
      text: pageFields?.intropage,
    } : {
      ...(parent) && {
        title: getTitle(parent?.pageFields?.intropage),
        text: parent?.pageFields?.intropage,
      }
    },
    ...(pageData?.featuredImage) ? {
      image: {
        image: pageData?.featuredImage?.node?.localFile?.childImageSharp,
        alt: pageData?.featuredImage?.node?.alt || '',
      }
    } : {
      image: {
        image: parent?.featuredImage?.node?.localFile?.childImageSharp,
        alt: parent?.featuredImage?.node?.alt || '',
      }
    },
    ...(pageData?.mobileImage) ? {
      mobileImage: {
        image: pageData?.mobileImage?.node?.localFile?.childImageSharp,
        alt: pageData?.mobileImage?.node?.alt || '',
      }
    } : {
      mobileImage: {
        image: parent?.mobileImage?.node?.localFile?.childImageSharp,
        alt: parent?.mobileImage?.node?.alt || '',
      }
    },
    ...(pageFields?.link1) ? {
      links: [
        pageFields?.link1 && pageFields?.label1 && {
          title: pageFields?.label1,
          to: pageFields?.link1?.uri,
        },
        pageFields?.link2 && pageFields?.label2 && {
          title: pageFields?.label2,
          to: pageFields?.link2?.uri,
        },
        pageFields?.link3 && pageFields?.label3 && {
          title: pageFields?.label3,
          to: pageFields?.link3?.uri,
        },
        pageFields?.link4 && pageFields?.label4 && {
          title: pageFields?.label4,
          to: pageFields?.link4?.uri,
        },
      ].filter(a => a)
    } : {
      links: [
        parent?.pageFields?.link1 && parent?.pageFields?.label1 && {
          title: parent?.pageFields?.label1,
          to: parent?.pageFields?.link1?.uri,
        },
        parent?.pageFields?.link2 && parent?.pageFields?.label2 && {
          title: parent?.pageFields?.label2,
          to: parent?.pageFields?.link2?.uri,
        },
        parent?.pageFields?.link3 && parent?.pageFields?.label3 && {
          title: parent?.pageFields?.label3,
          to: parent?.pageFields?.link3?.uri,
        },
        parent?.pageFields?.link4 && parent?.pageFields?.label4 && {
          title: parent?.pageFields?.label4,
          to: parent?.pageFields?.link4?.uri,
        }
      ].filter(a => a)
    }
  };
}

export const mapMembreToBanner = (membreData) => {
  return {
    title: membreData.title,
    text: membreData.excerpt,
    image: {
      image: membreData?.featuredImage?.node?.localFile?.childImageSharp,
      alt: membreData?.featuredImage?.node?.alt || '',
    },
    ...(membreData?.mobileImage) && {
      mobileImage: {
        image: membreData?.mobileImage?.node?.localFile?.childImageSharp,
        alt: membreData?.mobileImage?.node?.alt || '',
      }
    },
    links: []
  }
};

export const mapPostToBanner = (postData) => {
  return {
    title: postData.title,
    text: postData.excerpt,
    image: {
      image: postData?.featuredImage?.node?.localFile?.childImageSharp,
      alt: postData?.featuredImage?.node?.alt || '',
    },
    ...(postData?.mobileImage) && {
      mobileImage: {
        image: postData?.mobileImage?.node?.localFile?.childImageSharp,
        alt: postData?.mobileImage?.node?.alt || '',
      }
    },
    links: []
  }
};

const mSTB = (serviceData) => {
  const categorie = serviceData?.categoriesService?.nodes[0];

  const serviceFeaturedImage = getServiceImage('featured', categorie.slug);
  const serviceBlockImage = getServiceImage('block', categorie.slug);
  
  const links = categorie?.services?.nodes?.sort((a, b) => {
    return (a.date - b.date) > 0 ? -1 : 1;
  }).map(node => node.slug !== categorie.slug && {
    to: node.uri,
    title: node.title,
  }).filter(a => a);
  const image = serviceData?.featuredImage ? {
    image: serviceData?.featuredImage?.node?.localFile?.childImageSharp,
    alt: serviceData?.featuredImage?.node?.altText || '',
  } : {
    image: serviceFeaturedImage?.node?.localFile?.childImageSharp,
    alt: serviceFeaturedImage?.node?.altText || '',
  }
  const mobileImage = serviceData?.mobileImage ? {
    image: serviceData?.mobileImage?.node?.localFile?.childImageSharp,
    alt: serviceData?.mobileImage?.node?.alt || '',
  } : {
    image: serviceBlockImage?.node?.localFile?.childImageSharp,
    alt: serviceBlockImage?.node?.alt || '',
  };
  return {
    title: serviceData.title,
    text: serviceData.excerpt,
    image,
    mobileImage,
    links,
  }
};

export const mapServiceToBanner = memoize(mSTB);