import React from "react";
import { PrimaryButton } from "./styles";

// TODO : replace legacy component.
const Button = ({children, ...props}) => {
    return(
        <PrimaryButton {...props}>{children}</PrimaryButton>
  );
};

export default Button;