import React from "react";
import styled from 'styled-components';
import {AiFillPlusCircle} from "react-icons/ai";
import Link from './Core/Link';

export const BtnLink = styled(Link)`
    text-decoration: none;
    align-items: center;
    padding: 5px 10px 5px;
    color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
    border-radius: 20px;

    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 16px;

    &:hover {
      color: ${props => props.theme.color.white};
      background: ${props => props.theme.color.primary};
    }

    &.active {
      color: ${props => props.theme.color.white};
      background: ${props => props.theme.color.primary};
    }

    &.secondaryOrange {
      color: ${props => props.theme.color.secondaryOrange};
      border: 2px solid ${props => props.theme.color.secondaryOrange};

      &:hover {
        background: ${props => props.theme.color.secondaryOrange};
        color: ${props => props.theme.color.white};
      }

      &.active {
        background: ${props => props.theme.color.secondaryOrange};
        color: ${props => props.theme.color.white};
      }
    }
`;

export const Button = styled.button`
    text-decoration: none;
    align-items: center;
    padding: 5px 10px 5px;
    color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
    border-radius: 20px;
    margin: 2ex 2em;

    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 12px;

    &:hover {
        background: #FFFFFF;
        color: ${props => props.theme.color.primary};
    }
`;

const PlusSign = styled(AiFillPlusCircle)`
    color: ${props => props.theme.color.primary};
`;

const PlusBtnLinkComponent = ({to, children}) => (
  <Link to={to}>
    <PlusSign />&nbsp;
    {children}
  </Link>
);

export const PlusBtnLink = styled(PlusBtnLinkComponent)`
    text-decoration: none;
    display: flex;
    align-items: center;
`;

export const PlusBtnLinkContainer = styled.div`
  margin-top: 2rem;

  a {
    text-decoration: none;
    display: flex;
    align-items: center;

    svg {
      font-size: 1.6rem;
      margin-right: 0.5rem;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 2rem;

  ${props => props.theme.breakpoints.large} {
    padding: 4rem 16rem;
  }
`;