import React from "react";
// import {Contact } from "../../Core/ContactV1";
import ContactV1 from "../../Core/ContactV1";

export default ({location}) => {
  return (
    <React.Fragment>
      <ContactV1 location={location}/>
    </React.Fragment>
  )
}