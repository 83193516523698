import memoize from "fast-memoize";
import {useStaticQuery, graphql } from "gatsby";

const gSI = () => {
  const data = 
   useStaticQuery(graphql`
    query ServiceImagesQuery {
      serviceImages: allWpService(filter: {featuredImage: {node: {sourceUrl: {ne: null}}}}) {
        nodes {
          slug
          featuredImage {
            node {
              ...imageFull
            }
          }
          blockImage: featuredImage {
            node {
              ...imageBlock
            }
          }
        }
      }
    }
  `);
  const {serviceImages: {nodes: serviceImages}} = data;
  return serviceImages;
};
// const serviceImages = gSI();

export const getServiceImage = memoize((type: 'featured' | 'block', slug: string) => {
  const images = gSI().find(a => a.slug === slug);
  return images[`${type}Image`];
});