import React from "react";
import {Link} from "gatsby";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { StepsCardsWrapper, StepsCardsTitle } from "./styles";
import StepCardComponent from "./StepCard";
import Button from "../Button";

export type StepCard = {
  content: string;
  title: string;
  featuredImage: IGatsbyImageData; 
  picto: string;
}

type StepsCardsProps = {
  steps: StepCard[];
  title: string;
}

const StepsCards = ({steps, title} : StepsCardsProps) => {
    return (
      <StepsCardsWrapper>
        <StepsCardsTitle>{title}</StepsCardsTitle>
        {(steps || []).map((step, i) => (
          <StepCardComponent key={i} {...step} />
        ))}
        
      </StepsCardsWrapper>
    );
  };
  
  export default StepsCards;