import styled from 'styled-components';
import Link from "../../../Link";

export const NavPurple = styled.div`
    background-color: #635FC0;
    height: 2.875rem;
    display: flex;
    ${props => props.theme.breakpoints.large} {
        padding: 0 4em;
    }
    justify-content: flex-end;
    width: 100%;
`;

export const CampagneText = styled.p`
    display:flex;
    color: white;  
    align-items: center;
}
`;

export const Desktop = styled.span`
    display: none;
    ${props => props.theme.breakpoints.large} {
        display: inline;
    }
`;

export const Mobile = styled.span`
    ${props => props.theme.breakpoints.large} {
        display: none;
    }
`;

export const NavBtnLink = styled.a`
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 5px 10px 5px;
    color: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 20px;
    margin: 2ex 2em;

    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 12px;

    &:hover {
        background: #FFFFFF;
        color: ${props => props.theme.color.primary};
    }
`;

export const Nav = styled.nav`
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    padding: 0 1rem;
    height: 8rem;
    justify-content: center;

    ${props => props.theme.breakpoints.small} {
        height: 5rem;
    }

    ${props => props.theme.breakpoints.large} {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        height: 2.875rem;
        padding: 0 4rem;
    }
`;

export const NavLogo = styled(Link)`
    text-decoration: none;
`;

export const NavLink = styled(Link)`
    margin: 10px;
    text-decoration: none;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    &.active {
        color:#7BD5CC;
    }
    &:hover {
        color: #7BD5CC;
    }
`;

export const NavMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;

    ${props => props.theme.breakpoints.medium} {
        display: inline-block;
    }
`;
