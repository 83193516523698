import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import config from "../../../../../../config.json";
import {Nav, NavLogo, NavMenu, NavLink, NavBtnLink, NavPurple, CampagneText, Desktop, Mobile } from "./styles";
import Logo from "../../../Logo"
import ModalAppelProjets from "../../../../domain/Newsletter/AppelProjets/Modal";

const Navbar = ({title}) => {

    const data = useStaticQuery(graphql`
    query MainMenu{
        fr: wpMenu(slug: {eq: "principal"}) {
            menuItems {
            nodes {
                label
                url
            }
            }
      }
    }
    `);

    const items = data?.fr?.menuItems?.nodes;
    // 

    return (
        <React.Fragment>
            <NavPurple>
                <CampagneText>
                    <Desktop>
                        Rejoins la gang 25ᵉ heure&nbsp;
                    </Desktop>
                    <Mobile>
                        Rejoins la gang 25ᵉ heure&nbsp;
                    </Mobile>
                </CampagneText>
                <ModalAppelProjets />
            </NavPurple>
            <Nav>
                <NavLogo to="/">
                    <Logo title={title} />
                </NavLogo>
                <NavMenu>
                    {items.map(item => (
                        <NavLink key={item.url} activeClassName="active" to={item.url}>{item.label}</NavLink>    
                    ))}
                </NavMenu> 
            </Nav> 
        </React.Fragment>
    );
};

export default React.memo(Navbar);