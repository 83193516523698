import styled from 'styled-components';
import Link from "../../Link";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: #FFFFFF;
    drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
`;

export const Row = styled.div`
    width: 100vw;
    margin-left: 100px;
    margin-right: 100px;
    display: flex;
    align-items:center;
    flex-direction: column;

    ${props => props.theme.breakpoints.large} {
        flex-direction: row;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    div:first-child {
        margin: auto;
    }
    
    ${props => props.theme.breakpoints.large} {
        div:first-child {
            margin: 0;
        }
    }
`;

export const ColumnRight = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 0;
    margin-top: 2rem;
    
    div:first-child {
        margin: auto;
    }

    ${props => props.theme.breakpoints.large} {
        margin-top: 0;
        margin-left: auto;

        div:first-child {
            margin: 0;
        }
    }
`;

export const FooterLogo = styled(Link)`
    text-decoration: none;
    left: 0px;
    top: 0px;
`;

export const FooterLink = styled(Link)`
    text-decoration: none;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 12px;

    svg {
        font-size: 1.25rem;
    }

    color: ${props => props.theme.color.primary};
    &.active {
        color:#7BD5CC;
    }
    &:hover {
        color: #7BD5CC;
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export const FooterExtLink = styled.a`
    text-decoration: none;
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 400;
    font-size: 12px;

    svg {
        font-size: 1.25rem;
    }

    color: ${props => props.theme.color.primary};
    &.active {
        color:#7BD5CC;
    }
    &:hover {
        color: #7BD5CC;
    }

    &:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

export const Text = styled.div`
    font-family: ${props => props.theme.fonts.primary};
    font-weight: 300;
    font-size: 12px;
    color: ${props => props.theme.color.primary};
    text-decoration: none;
`;

export const Policy = styled.div`
    text-decoration: none;
    display: flex;
    align-items: center;

    a {
        margin-left: 1rem;
    }
`;

export const FooterForm = styled.div`
    display: flex;
    margin-top: 0.5rem;

    button {
        margin-left: 0.5rem;
    }
`;