import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position:relative;
`;

export const ModalBackdrop = styled.div`
  position: fixed;
  margin-top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.3);
  z-index: 2000;
`;

// type ModalContainerProps = {
//   theme: any;
// }

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  left:0;
  top:0;
  align-items: center;
  justify-content: center;
  overflow-y:auto;
`;

export const ModalContent = styled.div`
  padding: ${props => props.theme.space.xsmall};
  background: ${props => props.theme.color.primaryLight};
  border-radius: ${props => props.theme.radius.small};
  position: absolute;
  top:0;
`;

export const ModalTitle = styled.h2`

`;

type ModalHeaderProps = {
  withTitle: boolean;
  theme: any;
};

export const ModalHeader = styled.div`
display: flex;
justify-content: ${(props: ModalHeaderProps) => (
  props.withTitle ? 'space-between' : 'flex-end'
)}
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const ModalBody = styled.div`
  
`;
