export const onRouteUpdate = function ({location, prevLocation}) {
  if (
      process.env.NODE_ENV !== `production` && 
      typeof window === `undefined`) {
    return;
  }
  // console.log("gtag",location.pathname + location.search + location.hash);

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    typeof window.gtag !== `undefined` && window.gtag(`event`, `page_view`, { page_path: pagePath });
    typeof window.fbq !== `undefined` && window.fbq("track", "ViewContent");
  }

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView)
    })
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32)
  }
};