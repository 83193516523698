import React, { FC } from "react";
import { CloseButton, ToastContainer, ToastHeader, ToastTitle, ToastBody } from "./styles";
import { useToastContext } from "./hook";
import {AiOutlineClose} from "react-icons/ai";

export enum ToastType {
  cookies
};

interface ToastProps {
  title: string;
  type: ToastType;
  closeButton?: boolean;
  onHide?: () => void;
}

const Toast: FC<ToastProps> = ({
  title, 
  type,
  closeButton = false,
  onHide,
  children,
}) => {

  const {show, toggleToast, toastType} = useToastContext();

  const handleCloseToast = () => {
    onHide && onHide();
    toggleToast(false);
  }

  return show && type === toastType && (
      <ToastContainer 
        aria-live="polite"
        aria-atomic="true">
        <ToastHeader>
          <ToastTitle>{title}</ToastTitle>
          {closeButton && (
            <CloseButton onClick={handleCloseToast}>
              <AiOutlineClose />
            </CloseButton>
          )}
        </ToastHeader>
        <ToastBody>
          {children}
        </ToastBody>
      </ToastContainer>
  );
};

export default Toast;