import { Checkbox, CheckboxLabel, CheckboxContainer } from "./styles"
import React, { FC } from "react"
import { onCreateNode } from "../../../../../gatsby-node"

interface CheckboxProps {
  label: string;
  id: string;
  onChange: () => void;
};


const CheckboxComponent: FC<CheckboxProps> = ({
  label,
  id,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      <Checkbox 
        id={id}
        onChange={onChange} 
      />
      <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
    </CheckboxContainer>
  )
}

export default CheckboxComponent;