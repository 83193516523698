import React from "react";
import parse from "html-react-parser";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import { Container, SubContainer, BannerDesktop, BannerMobile, Subtitle, BannerContainer, LinksContainer, MobileSubContainer } from "./styles";
import { BtnLink } from "../styles";
import scrollTo from 'gatsby-plugin-smoothscroll';

const Banner = ({image, mobileImage = null, anchors = [], text, title, links = [], date=''}) => {

  const handleScrollTo = (evt,id) => {
    evt.preventDefault();
    scrollTo(`#${id}`);
  }

  return (
  <>
    <Container>
      {image && (
      <BannerContainer>
        <BannerDesktop>
          <GatsbyImage className='banner' loading="eager" image={getImage(image?.image)} alt={image?.alt}/>
        </BannerDesktop>
      </BannerContainer>
      )}
      {mobileImage && (
        <BannerContainer>
          <BannerMobile>
            <GatsbyImage className='banner' loading="eager" image={getImage(mobileImage?.image)} alt={mobileImage?.alt} />
          </BannerMobile>
      </BannerContainer>
      )}
      <SubContainer>
        {!!title && 
          <Subtitle>{title}</Subtitle>
        }
        {!!date && (
          <p>{date}</p>
        )}
        {!!text && (
          <React.Fragment>
            {parse(text)}
          </React.Fragment>
        )}
        {anchors.length > 0 ? (
          <LinksContainer>
          {anchors.map(link => (
            <BtnLink key={link.name} activeClassName="active" to={`#${link.name}`} onClick={(evt) => handleScrollTo(evt,link.name)}>
              {link.title}
            </BtnLink>
          ))}
        </LinksContainer>
        ) : (
          <LinksContainer>
            {links.map(link => (
              <BtnLink key={link.to} activeClassName="active" to={link.to}>
                {link.title}
              </BtnLink>
            ))}
          </LinksContainer>
        )}
      </SubContainer>
    </Container>
    <MobileSubContainer>
      {!!title && 
            <Subtitle>{title}</Subtitle>
          }
          {!!date && (
            <p>{date}</p>
          )}
          {!!text && (
            <React.Fragment>
              {parse(text)}
            </React.Fragment>
          )}
          {anchors.length > 0 ? (
            <LinksContainer>
            {anchors.map(link => (
              <BtnLink key={link.name} activeClassName="active" to={`#${link.name}`} onClick={(evt) => handleScrollTo(evt,link.name)}>
                {link.title}
              </BtnLink>
            ))}
          </LinksContainer>
          ) : (
            <LinksContainer>
              {links.map(link => (
                <BtnLink key={link.to} activeClassName="active" to={link.to}>
                  {link.title}
                </BtnLink>
              ))}
            </LinksContainer>
          )}
    </MobileSubContainer>
    
  </>
  );
};

export default React.memo(Banner);