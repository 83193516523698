import React from "react";
import {Link} from "gatsby";
import parse from "html-react-parser"
import {Card} from "../index";
import { CardWrapper, CardAuthorWrapper, CardHeader, CardBody, CardLink, CardContainer } from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { formatDate } from "../../../../utils/dates";

const CardComponent = ({title, author, date, featuredImage, excerpt, uri}: Card) => {

  return (
    <CardWrapper className='card-wrapper'>
      <CardLink to={uri}>
        {featuredImage && (<GatsbyImage image={featuredImage.image} alt={featuredImage.alt}/>)}
          
        {/* <Categorie></Categorie> */}
        <CardContainer>
          <CardHeader>{title}</CardHeader>
          <CardBody>{parse(excerpt)}</CardBody>
          {(author && date) && (
            <CardAuthorWrapper>
              <div>
                Par {author}
              </div>
              <div>
                Le {formatDate(date)}
              </div>
            </CardAuthorWrapper>
          )}
        </CardContainer>
        </CardLink>
    </CardWrapper>
  );
};

export default CardComponent;