import React from "react"

import {GlobalWrapper} from "./styles";
import Header from "../components/Core/Layout/Header";
import Footer from "../components/Core/Layout/Footer";
import {Main} from "./styles";
import { theme } from "../styles/theme";
import { ThemeProvider } from "styled-components";
import { getWpConfig } from "../utils/wpConfig";
import { ModalProvider } from "../components/Core/Modal/context";
import { ToastProvider } from "../components/Core/Toast/context";
import Gdpr from "../components/Core/Gdpr";
import { isBrowser } from "../utils/gatsby";

//TODO: react-helmet
declare global {
  interface Window { loadGtag: any; }
}
const Layout = ({ isHomePage = false, children }) => {
  const { title, description } = getWpConfig();
  const CookiesBody = () => (
    <p>
      Pour nous permettre d’améliorer la navigation et votre expérience sur le site 25e heure, nous utilisons plusieurs cookies. 
    </p>
  ); 
  return (
    <GlobalWrapper className="global-wrapper" data-is-root-path={isHomePage}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <ToastProvider>
            <Gdpr 
              texts={{
                title: "Cookies",
                body: <CookiesBody />,
                accept: "Accepter",
                moreInfo: "Plus d'infos"
              }}
              cookies={[
                {
                  name: 'gtag',
                  label: 'Google Analytics',
                  loadFunction: isBrowser() && window.loadGtag,
                  storageKey: 'gtag_optin',
                }
              ]}
            />
            <Header isHomePage={isHomePage} title={title} description={description} />
            <Main><main>{children}</main></Main>
            <Footer title={title} />  
          </ToastProvider>
        </ModalProvider>
      </ThemeProvider>
    </GlobalWrapper>
  )
}

export default Layout
