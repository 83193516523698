import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import {Container, Row, Column, FooterLink, Text, Policy, ColumnRight, FooterForm, FooterExtLink } from "./styles";
import Logo from "../../Logo";
import Button from "../../../Button";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin} from "react-icons/fa";
import MailChimp from "../../../domain/Newsletter/MailChimp";


const Footer = ({ title }) => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      fr: wpMenu(slug: {eq: "pied-de-page"}) {
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  `);
  const items = data?.fr?.menuItems?.nodes;
  return (
    <footer className='main-footer'>
      <Container>
        <Row>
          <Column>
            <Logo title={title} />
            <MailChimp />
          </Column>
          <ColumnRight>
            <div>
              <FooterExtLink href="https://www.facebook.com/25eheure.coop/" target="_blank"><FaFacebookSquare /></FooterExtLink>
              <FooterExtLink href="https://www.instagram.com/25eheure_coop/" target="_blank"><FaInstagramSquare /></FooterExtLink>
              <FooterExtLink href="https://www.linkedin.com/company/25eheure/" target="_blank"><FaLinkedin /></FooterExtLink>
            </div>
            <Policy>
              <Text>©2021 - 25e heure</Text>
              {(items || []).map(item => (
                <FooterLink key={item.url} to={item.url}>{item.label}</FooterLink>
              ))}
            </Policy>
          </ColumnRight>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

