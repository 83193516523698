

export const mapServiceDataToItems = (services) => {
  return services?.edges.map((s) => {
    const {node} = s;
    const {contentNodes} = node;
    const contentNode = contentNodes?.nodes.find((n) => (n.slug === node.slug));
    const categorieNodes = contentNodes?.nodes.find((n) => (n.slug !== node.slug));
    return {
      title: contentNode?.title || node.name,
      picto: contentNode?.servicesFields?.picto || node.slug,
      uri: categorieNodes?.uri,
      slug: node.slug,
    }
  });
};

export const mapServiceDataToServiceList = (services) => {
  return services?.edges.map((s) => {
    const {node} = s;
    const {contentNodes} = node;
    //Main service
    const contentNode = contentNodes?.nodes.find((n) => (n.slug === node.slug));
    return {
      title: contentNode?.title || node.name,
      picto: contentNode?.servicesFields?.picto || node.slug,
      uri: contentNode?.uri,
      excerpt: contentNode?.excerpt,
      slug: node.slug,
      children: contentNodes?.nodes.map((n) => {
        const {servicesFields, ...childData} = n;
        return n.slug !== node.slug && {
          ...childData,
          picto: servicesFields?.picto
        } 
      }).filter(a => a).sort((a,b) => (a.date - b.date) > 0 ? -1 : 1)
    }
  });
};