import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  ${props => props.theme.breakpoints.large} {
    padding: 8rem 12rem;
  }
`;
